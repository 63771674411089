import React from "react"
import Section from "elements/Section"
import classNames from "classnames"
import styles from "./utils/bookingSummary.module.scss"

const PersonalDetails = ({ userData, addresses }) => {
  const firstName = userData?.firstName
  const lastName = userData?.lastName
  const middleName = userData?.middleName ? ` ${userData?.middleName} ` : " "
  const fullName = firstName + middleName + lastName

  const address = addresses?.addresses?.find((address) => address.primary)

  return (
    <Section
      title="Personal Details"
      // addOns={{
      //   right: <EditDetailsButton route={section.link} />,
      // }}
    >
      <div className="table-container">
        <table class="table is-fullwidth is-size-5">
          <tbody>
            <tr>
              <td>Name</td>
              <td
                className={classNames(
                  "has-text-weight-bold",
                  styles["summary__tableData"]
                )}
              >
                {fullName}
              </td>
            </tr>
            <tr>
              <td>Birthday</td>
              <td
                className={classNames(
                  "has-text-weight-bold",
                  styles["summary__tableData"]
                )}
              >
                {userData?.birthday?.month?.value}{" "}
                {userData?.birthday?.date?.value}, {userData?.birthday?.year}
              </td>
            </tr>
            {userData?.gender && (
              <tr>
                <td>Gender</td>
                <td
                  className={classNames(
                    "has-text-weight-bold",
                    styles["summary__tableData"]
                  )}
                >
                  {userData?.gender}
                </td>
              </tr>
            )}
            <tr>
              <td>Mobile Number</td>
              <td
                className={classNames(
                  "has-text-weight-bold",
                  styles["summary__tableData"]
                )}
              >
                {userData?.mobileNumber}
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td
                className={classNames(
                  "has-text-weight-bold",
                  styles["summary__tableData"]
                )}
              >
                {address?.streetAddress}, {address?.barangay}, {address?.city}, {address?.province}
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td
                className={classNames(
                  "has-text-weight-bold",
                  styles["summary__tableData"]
                )}
              >
                {userData?.email}
              </td>
            </tr>
            <tr>
              <td>Site</td>
              <td
                className={classNames(
                  "has-text-weight-bold",
                  styles["summary__tableData"]
                )}
              >
                {userData?.site?.label}
              </td>
            </tr>
            <tr>
              <td>Account/Department</td>
              <td
                className={classNames(
                  "has-text-weight-bold",
                  styles["summary__tableData"]
                )}
              >
                {userData?.department}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Section>
  )
}

export default PersonalDetails
