import { convertToBoolean } from "services/general"
import { bookingZendeskTemplate } from "../utils/bookingZendeskTemplate"
import { computeKesslerScore } from "./computeKesslerScore"

import {
  GATSBY_REQUEST_OBJECT_FIELD_ID,
  GATSBY_MOBILE_NUMBER_FIELD_ID,
  GATSBY_PREFERRED_SCHEDULE_FIELD_ID,
  GATSBY_KESSLER_SCORE_FIELD_ID,
  GATSBY_WEBSITE_URL,
  GATSBY_ENV,
  GATSBY_MAKE_CREATE_NEW_REQUEST_WEBHOOK_URL,
} from "gatsby-env-variables"

const TEST_ENV = GATSBY_ENV === "production" ? "" : "[TEST] "

export const generateConsultFirebaseDocument = ({ requestData, zendeskId, userData }) => {
  let user = userData

  const preferredSchedule = {
    firstChoiceSchedule: {
      day: requestData.firstChoiceSchedule.day.value,
      time: requestData.firstChoiceSchedule.time.value

    },
    secondChoiceSchedule: {
      day: requestData.secondChoiceSchedule.day.value,
      time: requestData.secondChoiceSchedule.time.value

    },
    thirdChoiceSchedule: {
      day: requestData.thirdChoiceSchedule.day.value,
      time: requestData.thirdChoiceSchedule.time.value

    }
  }

  let document = {
    created: new Date(),
    hasConsultedWithPsychOutsideProgram: requestData.hasConsultedWithPsychOutsideProgram ? requestData.hasConsultedWithPsychOutsideProgram : "",
    experienceWithPsychOutsideProgram: requestData.experienceWithPsychOutsideProgram.value ? requestData.experienceWithPsychOutsideProgram.value : "",
    hasConsultedWithPsychInProgram: requestData.hasConsultedWithPsychInProgram ? requestData.hasConsultedWithPsychInProgram : "",
    previousPsychName: requestData.previousPsychName ? requestData.previousPsychName : "",
    hasBeenDiagnosed: requestData.hasBeenDiagnosed ? requestData.hasBeenDiagnosed : "",
    mentalIllnessDiagnosis: requestData.mentalIllnessDiagnosis ? requestData.mentalIllnessDiagnosis : "",
    hasFamilyHistory: requestData.hasFamilyHistory ? requestData?.hasFamilyHistory : "",
    specificFamilyHistory: requestData.specificFamilyHistory ? requestData.specificFamilyHistory : "",
    hasBeenHospitalized: requestData.hasBeenHospitalized ? requestData.hasBeenHospitalized : "",
    specificHospitalizationDetails : requestData.specificHospitalizationDetails ? requestData.specificHospitalizationDetails : "",
    isTakingPsychMeds: requestData.isTakingPsychMeds ? requestData.isTakingPsychMeds : "",
    psychMedsDetails: requestData.psychMedsDetails ? requestData.psychMedsDetails : "",
    isTakingNonPsychMeds: requestData.isTakingNonPsychMeds ? requestData.isTakingNonPsychMeds : "",
    nonPsychMedsDetails: requestData.nonPsychMedsDetails ? requestData.nonPsychMedsDetails : "",
    hasDrugAllergies: requestData.hasDrugAllergies ? requestData.hasDrugAllergies : "",
    drugAllergiesDetails: requestData.drugAllergiesDetails ? requestData.drugAllergiesDetails : "",
    causeOfDistress: requestData.causeOfDistress ? requestData.causeOfDistress : "",
    specificCauseOfDistress: requestData.specificCauseOfDistress ? requestData.specificCauseOfDistress : "",
    durationCurrentCauseOfDistress: requestData.durationCurrentCauseOfDistress ? requestData.durationCurrentCauseOfDistress : "",
    currentMentalHealthCondition: requestData.currentMentalHealthCondition ? requestData.currentMentalHealthCondition : "",

    kesslerNervous: requestData.kesslerNervous ? requestData.kesslerNervous : "",
    kesslerHopeless: requestData.kesslerHopeless ? requestData.kesslerHopeless : "",
    kesslerRestless: requestData.kesslerRestless ? requestData.kesslerRestless : "",
    kesslerDepressed: requestData.kesslerDepressed ? requestData.kesslerDepressed : "",
    kesslerEffort: requestData.kesslerEffort ? requestData.kesslerEffort : "",
    kesslerWorthless: requestData.kesslerWorthless ? requestData.kesslerWorthless : "",
    hasWishedToBeDead: requestData.hasWishedToBeDead ? requestData.hasWishedToBeDead : "",
    hadSuicidalThoughts: requestData.hadSuicidalThoughts ? requestData.hadSuicidalThoughts : "",

    hasThoughtSuicidalMethod: requestData.hasThoughtSuicidalMethod ? requestData.hasThoughtSuicidalMethod : "",
    hadSuicidalIntent: requestData.hadSuicidalIntent ? requestData.hadSuicidalIntent : "",
    hadSuicidalIntentWithPlan: requestData.hadSuicidalIntentWithPlan ? requestData.hadSuicidalIntentWithPlan : "",
    hasActedOnSuicidalThoughts: requestData.hasActedOnSuicidalThoughts ? requestData.hasActedOnSuicidalThoughts : "",
    hasActedOnThoughtsWithinThreeMonths: requestData.hasActedOnThoughtsWithinThreeMonths ? requestData.hasActedOnThoughtsWithinThreeMonths : "",
    hasThoughtsOfHarmingOthers: requestData.hasThoughtsOfHarmingOthers ? requestData.hasThoughtsOfHarmingOthers : "",
    hasCopingAttempts: requestData.hasCopingAttempts ? requestData.hasCopingAttempts : "",
    
    preferredSchedule,
    ifScheduleUnavailable: requestData.ifScheduleUnavailable ? requestData.ifScheduleUnavailable : "",
    specificIfScheduleUnavailable: requestData.specificIfScheduleUnavailable ? requestData.specificIfScheduleUnavailable : "",
    therapistPreference: requestData.therapistPreference ? requestData.therapistPreference : "",
    otherNotes: requestData.otherNotes ? requestData.otherNotes : "",
    userId: user.id,
    authUid: user.authUid,
    type: "MGXSOLMH",
  }

  document = convertToBoolean({data: document})

  if (zendeskId) {
    document.zendeskId = zendeskId
  }

  return document
}

export const generateZendeskRequestTemplate = ({
  mentalHealthState,
  userData,
  addresses,
}) => {
  let tags = ["alorica", "alorica_mh", "new_request", "self"]
  if (GATSBY_ENV !== "production") tags.push("test")

  const fullName = userData?.firstName + " " + userData?.lastName

  const subject = `[Alorica ${userData?.site?.label || ""}] ${
    fullName || ""
  }: Teleconsult Request`

  let mentalHealthRequestState = {
    ...mentalHealthState,
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    mobileNumber: userData?.mobileNumber || "",
    birthday: userData?.birthday || "",
    gender: userData?.gender || "",
    site: userData?.site || "",
    department: userData?.department || "",
    employeeNumber: userData?.employeeNumber || "",
    emergencyContactName: userData?.emergencyContact?.name || "",
    emergencyContactNumber: userData?.emergencyContact?.mobileNumber || "",
    emergencyContactRelationship:
      userData?.emergencyContact?.relationship || "",
    address: addresses?.addresses?.find((address) => address.primary) || "",
  }

  let customFields = []
  let priority = "normal"
  customFields.push({
    id: GATSBY_MOBILE_NUMBER_FIELD_ID,
    value: userData?.mobileNumber,
  })
  const formattedSchedule1 = mentalHealthState?.firstChoiceSchedule?.day?.value
    ? `${mentalHealthState?.firstChoiceSchedule?.day?.value} ${mentalHealthState?.firstChoiceSchedule?.time?.value}`
    : ``
  const formattedSchedule2 = mentalHealthState?.secondChoiceSchedule?.day?.value
    ? `; ${mentalHealthState?.secondChoiceSchedule?.day?.value} ${mentalHealthState?.secondChoiceSchedule?.time?.value}`
    : ``
  const formattedSchedule3 = mentalHealthState?.thirdChoiceSchedule?.day?.value
    ? `; ${mentalHealthState?.thirdChoiceSchedule?.day?.value} ${mentalHealthState?.thirdChoiceSchedule?.time?.value}`
    : ``
  customFields.push({
    id: GATSBY_PREFERRED_SCHEDULE_FIELD_ID,
    value: `${formattedSchedule1}${formattedSchedule2}${formattedSchedule3}`,
  })

  if (mentalHealthState?.hasWishedToBeDead.includes("No")) {
    // Meaning: Kessler6 route
    const {
      kesslerDepressed,
      kesslerEffort,
      kesslerHopeless,
      kesslerNervous,
      kesslerRestless,
      kesslerWorthless,
    } = mentalHealthState
    const kessler6 = {
      kesslerDepressed,
      kesslerEffort,
      kesslerHopeless,
      kesslerNervous,
      kesslerRestless,
      kesslerWorthless,
    }
    customFields.push({
      id: GATSBY_KESSLER_SCORE_FIELD_ID,
      value: computeKesslerScore(kessler6),
    })
  } else {
    // Meaning: CSSRS route
    const {
      hadSuicidalIntent,
      hadSuicidalIntentWithPlan,
      hasActedOnSuicidalThoughts,
    } = mentalHealthState
    const alertQuestions = [
      hadSuicidalIntent,
      hadSuicidalIntentWithPlan,
      hasActedOnSuicidalThoughts,
    ]
    if (alertQuestions?.includes("Yes")) {
      priority = "urgent"
    } else {
      priority = "normal"
    }
  }

  customFields.push({
    id: GATSBY_REQUEST_OBJECT_FIELD_ID,
    value: JSON.stringify(mentalHealthRequestState),
  })

  return {
    request: {
      type: "request",
      subject: `${TEST_ENV}${subject}`,
      requester: {
        name: fullName || "",
        email: userData?.email || "it@medgrocer.com",
      },
      email_ccs: [{ user_email: userData?.personalEmail || userData?.email }],
      comment: {
        body: bookingZendeskTemplate({
          mentalHealthState,
          userData,
          addresses,
        }),
      },
      custom_fields: customFields,
      priority,
      tags,
    },
  }
}